import React from "react";
import { Layout, Seo } from "components";
import { Link } from 'gatsby';

// import page style
import "./index.scss";

const IndexPage = () => {
  return (
    <Layout indication="patient" className="patient-home">
      <Seo pageTitle="Transdermal System"
      pageDescription="Learn about Butrans. See Full Prescribing Info, Med Guide & Boxed Warning." />
      <div className="full-width-bg">
        <div className="body-content-container">
          <div className="body-content gutter-all">
                <div className="header-section">
                    <h1>Information About the <h2>7-Day Butrans Patch</h2>
                    </h1>
                    <Link className="link-arrow" to="/resources">See more about the Butrans.</Link>
                </div>
                <div className="callout-section">
                 
                </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
